
<template>
  <div class="top-article-box-sp">
    <a :href="`articles/${article.url_word}/${article.id}`" class="top-article-box-link-sp">
      <img :src="article.image_path" width="100%">
      <div class="top-article-date-sp">{{article.date}}</div>
      <div class="top-article-title-sp">{{article.title}}</div>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    article: {
      type: Object,
      default: () => {}
    },
  },
  components: {
  },
  methods: {
    
  },
  created(){
  },
  computed: {
  },
  watch: {
  }
}
</script>