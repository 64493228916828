<template>
<!-- Modal -->
  <div v-show="active" :class="modalClass" style="display: block;">
    <div class="modal-dialog">
      <div class="card card-signup card-plain">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">所在地を選択してください</h5>
            <button type="button" class="close" @click="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <select-accordion
              ref="selectAccordion"
              v-for="place in places"
              :key="place.prefecture"
              :name="place.prefecture"
              :items="place.cities"
              :initItems="initPlace"
              @add-item="addItem"
              @delete-item="deleteItem"
            />
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="cancel-button mr-2" @click="close()">キャンセル</button>
            <button type="button" class="select-button" @click="clickAddButton">追加</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectAccordion from './selectAccordion.vue'
export default {
  data() {
    return {
      active: false,
      selectedItems: []
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    places: {
      type: Array,
      default: () => []
    },
    initPlace: {
      type: Array,
      default: () => []
    }
  },
  components: {
    selectAccordion
  },
  methods: {
    close(){
      this.active = false;
      this.$emit("close");
    },
    clickAddButton(){
      let addItems = [];
      this.places.forEach(place => {
        place.cities.forEach(city => {
          if(this.selectedItems.includes(city.id)){
            addItems.push({label: `${place.prefecture}/${city.name}`, id: city.id});
          }
        })
        // addItems = addItems.concat(place.selectedCities.map(x => {return {label: `${place.prefecture}/${x.name}`, id: x.id}}))
      });
      this.$emit("add-place",addItems);
      this.close();
    },
    addItem(event){
      this.selectedItems.push(event)
    },
    deleteItem(event){
      this.selectedItems = this.selectedItems.filter(x => x != event)
    }
  },
  created(){
    this.selectedItems = this.initPlace.map(x => x.id);
  },
  computed: {
    modalClass(){
      return this.active ? "modal show fadein" : "modal"
    },
  },
  watch: {
    isActive(val){
      this.modalStyle = "display: block;"
      this.active = val
    }
  }
}
</script>