<template>
  <div class="search-container">
    <div class="row border-bottom search-box">
      <div class="col-12 search-item-label">
        {{label}}
      </div>
      <div class="col-10 search-item-field">
        <search-icon v-for="item in items" :item="item" :key="item.id" @delete-item="deleteItem"/>
      </div>
      <div class="col-2 add-search-item">
        <button class="add-button" @click="clickPlus">
          <span class="plus-icon">+</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import searchIcon from './searchIcon.vue'
export default {
  data() {
    return {
      
    }
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: []
    },
  },
  components: {
    searchIcon
  },
  methods: {
    deleteItem(id){
      this.$emit("delete-item", id);
    },
    clickPlus(){
      this.$emit("click-plus");
    }
  },
  created(){
    
  },
  computed: {
    
  },
  watch: {
    
  }
}
</script>