<template>
  <span class="selected-item-primary">
    <span>{{item.label}}</span><span class="delete-search-item" @click="deleteItem(item.id)">×</span>
  </span>
</template>
<script>
export default {
  data() {
    return {
  
    }
  },
  props: {
    item: {
      type: Object,
      default: {}
    }
  },
  components: {
  },
  methods: {
    deleteItem(id) {
      this.$emit("delete-item", id);
    }
  },
  created(){
    
  },
  computed: {
    
  },
  watch: {
    
  }
}
</script>