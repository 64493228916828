<template>
  <canvas ref="mapCanvas" class="japan-map"></canvas>
</template>
<script>
export default {
  data() {
    return {
      existedFaciliyNum: [
        12919, // 北海道
        3436,	 // 青森県
        3120,	 // 岩手県
        4452,	 // 宮城県
        3098,	 // 秋田県
        3011,	 // 山形県
        5197,	 // 福島県
        6266,	 // 茨城県
        4796,	 // 栃木県
        4235,	 // 群馬県
        10196, // 埼玉県
        9284,	 // 千葉県
        12162, // 東京都
        9922,	 // 神奈川県
        5725,	 // 新潟県
        2422,	 // 富山県
        2861,	 // 石川県
        1947,	 // 福井県
        2342,	 // 山梨県
        6303,	 // 長野県
        4536,	 // 岐阜県
        6445,	 // 静岡県
        10467, // 愛知県
        3520,	 // 三重県
        2607,	 // 滋賀県
        3960,	 // 京都府
        9236,	 // 大阪府
        8041,	 // 兵庫県
        2305,	 // 奈良県
        2140,	 // 和歌山県
        1614,	 // 鳥取県
        2209,	 // 島根県
        3929,	 // 岡山県
        5335,	 // 広島県
        3591,	 // 山口県
        1944,	 // 徳島県
        2126,	 // 香川県
        3098,	 // 愛媛県
        2038,	 // 高知県
        6832,	 // 福岡県
        1948,	 // 佐賀県
        3460,	 // 長崎県
        4211,	 // 熊本県
        3014,	 // 大分県
        2940,	 // 宮崎県
        4985,	 // 鹿児島県
        2308	 // 沖縄県
      ]
    } 
  },
  props: {
    prefectureFacilityInfo: {
      type: Array,
      default: () => []
    },
    canvasWidth: {
      type: Number,
      default: 0
    },
    canvasHeight: {
      type: Number,
      default: 0
    },
    scale: {
      type: Number,
      default: 0
    },
    lineWeight: {
      type: Number,
      default: 0
    },
  },
  components: {
  
  },
  methods: {
    draw_rectangle(stx, sty, lenx, leny, color, dir1, context, dir2) {

      context.strokeRect(stx, sty, lenx, leny);
      context.fillStyle = color;
      context.fillRect(stx+this.lineWeight, sty+this.lineWeight, lenx-this.lineWeight*2, leny-this.lineWeight*2);

      switch(dir1) {
        case "ne":
          var nxtpos = [stx + lenx, sty];
          break;
        case "se":
          var nxtpos = [stx + lenx, sty + leny];
          break;
        case "sw":
          var nxtpos = [stx, sty + leny];
          break;
        case "nw":
          var nxtpos = [stx, sty];
          break;
      }
      if (dir2 !== undefined) {
        switch(dir2) {
          case "ne":
            nxtpos.push(stx + lenx, sty);
            break;
          case "se":
            nxtpos.push(stx + lenx, sty + leny);
            break;
          case "sw":
            nxtpos.push(stx, sty + leny);
            break;
          case "nw":
            nxtpos.push(stx, sty);
            break;
        }
      }
      return nxtpos;
    },

    draw_2_diff_squares(stx, sty, lenx, leny, color, pdir1, context, rmx, rmy, rdir, pdir2) {
      switch(rdir) {
          case "ne":
            context.strokeRect(stx, sty, lenx-rmx, leny);
            context.strokeRect(stx, sty+rmy, lenx, leny-rmy);
            context.fillStyle = color;
            context.fillRect(stx+this.lineWeight, sty+this.lineWeight, lenx-rmx-this.lineWeight*2, leny-this.lineWeight*2);
            context.fillStyle = color;
            context.fillRect(stx+this.lineWeight, sty+rmy+this.lineWeight, lenx-this.lineWeight*2, leny-rmy-this.lineWeight*2);
            break;
          case "se":
            context.strokeRect(stx, sty, lenx, leny - rmy);
            context.strokeRect(stx, sty, lenx - rmx, leny);
            context.fillStyle = color;
            context.fillRect(stx+this.lineWeight, sty+this.lineWeight, lenx-this.lineWeight*2, leny-rmy-this.lineWeight*2);
            context.fillStyle = color;
            context.fillRect(stx+this.lineWeight, sty+this.lineWeight, lenx-rmx-this.lineWeight*2, leny-this.lineWeight*2);
            break;
          case "sw":
            context.strokeRect(stx, sty, lenx, leny-rmy);
            context.strokeRect(stx + rmx, sty, lenx-rmx, leny);
            context.fillStyle =  color;
            context.fillRect(stx+this.lineWeight, sty+this.lineWeight, lenx-this.lineWeight*2, leny-rmy-this.lineWeight*2);
            context.fillStyle =  color;
            context.fillRect(stx+rmx+this.lineWeight, sty+this.lineWeight, lenx-rmx-this.lineWeight*2, leny-this.lineWeight*2);
            break;
          case "nw":
            context.strokeRect(stx+rmx, sty, lenx-rmx, leny);
            context.strokeRect(stx, sty+rmy, lenx, leny-rmy);
            context.fillStyle = color;
            context.fillRect(stx+rmx+this.lineWeight, sty+this.lineWeight, lenx-rmx-this.lineWeight*2, leny-this.lineWeight*2);
            context.fillStyle = color;
            context.fillRect(stx+this.lineWeight, sty+rmy+this.lineWeight, lenx-this.lineWeight*2, leny-rmy-this.lineWeight*2);
            break;
      }
      switch(pdir1) {
        case "ne":
          var nxtpos = [stx + lenx, sty];
          break;
        case "se":
          var nxtpos = [stx + lenx, sty + leny];
          break;
        case "sw":
          var nxtpos = [stx, sty + leny];
          break;
        case "nw":
          var nxtpos = [stx, sty];
          break;
        case "cen":
          var nxtpos = [stx + lenx - rmx, sty + leny - rmy];
      }
      if (pdir2 !== undefined) {
        switch(pdir2) {
          case "ne":
            nxtpos.push(stx + lenx, sty);
            break;
          case "se":
            nxtpos.push(stx + lenx, sty + leny);
            break;
          case "sw":
            nxtpos.push(stx, sty + leny);
            break;
          case "nw":
            nxtpos.push(stx, sty);
            break;
        }
      }
      return nxtpos;
    },

    select_color(peopleRatio) {
      if (peopleRatio > 0.9) {
        return "#FFA500";
      }
      else if (peopleRatio > 0.8) {
        return "#FFAE19";
      }
      else if (peopleRatio > 0.7) {
        return "#FFB732";
      }
      else if (peopleRatio > 0.6) {
        return "#FEC04C";
      }
      else if (peopleRatio > 0.5) {
        return "#FFC965";
      }
      else if (peopleRatio > 0.4) {
        return "#FFD27F";
      }
      else if (peopleRatio > 0.3) {
        return "#FFDB99";
      }
      else if (peopleRatio > 0.2) {
        return "#FFE4B2";
      }
      else if (peopleRatio > 0.1) {
        return "#FEEDCC";
      }
      else if (peopleRatio > 0) {
        return "#FFF6E5";
      }
      else {
        return "#FFFFFF";
      }
    },
    getPrefectureColorBasedOnFacilityRate() {
      var PrefectureColor = {};

      for (let i = 0; i < this.existedFaciliyNum.length; i++) {
        PrefectureColor[this.prefectureFacilityInfo[i].name] = this.select_color(this.prefectureFacilityInfo[i].count / this.existedFaciliyNum[i]);
      }
      return PrefectureColor;
    },
    drawKyushuArea(startx, starty, ctx, color) {
      
      let nextPoint = this.draw_rectangle(startx, starty, 20*this.scale, 50*this.scale, color.長崎県, "ne", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 20*this.scale, 50*this.scale, color.佐賀県, "ne", ctx, "se");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1], 60*this.scale, 50*this.scale, color.福岡県, "cen", ctx, 25*this.scale, 35*this.scale, "se", 'ne');
      let nextAreaPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 25*this.scale, 45*this.scale, color.大分県, "sw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 25*this.scale, 47*this.scale, color.宮崎県, "ne", ctx);
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 35*this.scale, 57*this.scale, color.熊本県, "sw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 60*this.scale, 35*this.scale, color.鹿児島県, "sw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1] + 15*this.scale, 27*this.scale, 35*this.scale, color.沖縄県, "sw", ctx);

      return nextAreaPoint;
    },
    drawShikokuArea(startx, starty, ctx, color) {

      let nextPoint = this.draw_rectangle(startx, starty, 41*this.scale, 29*this.scale, color.愛媛県, "ne", ctx, "sw");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 41*this.scale, 29*this.scale, color.香川県, "ne", ctx);
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 41*this.scale, 29*this.scale, color.高知県, "ne", ctx, "sw");
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 41*this.scale, 29*this.scale, color.徳島県, "ne", ctx, "sw");
    },
    drawChugokuArea(startx, starty, ctx, color) {
      let nextPoint = this.draw_rectangle(startx, starty, 24*this.scale, 70*this.scale, color.山口県, "ne", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 24*this.scale, 35*this.scale, color.島根県, "sw", ctx, "ne");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 24*this.scale, 35*this.scale, color.広島県, "sw", ctx);
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 24*this.scale, 35*this.scale, color.鳥取県, "sw", ctx, "ne");
      subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 24*this.scale, 35*this.scale, color.岡山県, "sw", ctx);
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 24*this.scale, 70*this.scale, color.兵庫県, "ne", ctx);

      return nextPoint;
    },
    drawKansaiArea(startx, starty, ctx, color) {
      let nextPoint = this.draw_2_diff_squares(startx, starty, 47*this.scale, 55*this.scale, color.京都府, "sw", ctx, 25*this.scale, 25*this.scale, "ne");
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 23*this.scale, 45*this.scale, color.大阪府, "sw", ctx, "ne");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1], 47*this.scale, 43*this.scale, color.和歌山県, "sw", ctx, 24*this.scale, 20*this.scale, "ne");
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 24*this.scale, 65*this.scale, color.奈良県, "ne", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 24*this.scale, 88*this.scale, color.三重県, "nw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1]-30*this.scale, 24*this.scale, 30*this.scale, color.滋賀県, "nw", ctx);
      
      return nextPoint;
    },
    drawHokurikuArea(startx, starty, ctx, color) {
      let nextPoint = this.draw_rectangle(startx, starty-90*this.scale, 24*this.scale, 50*this.scale, color.石川県, "sw", ctx, "se");
      let nextAreaPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_2_diff_squares(nextPoint[0]-25*this.scale, nextPoint[1], 49*this.scale, 40*this.scale, color.福井県, "ne", ctx, 25*this.scale, 15*this.scale, "nw");
      nextPoint = this.draw_rectangle(startx+24*this.scale, starty-74*this.scale, 36*this.scale, 34*this.scale, color.富山県, "ne", ctx);
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1]-15*this.scale, 49*this.scale, 49*this.scale, color.新潟県, "ne", ctx, 32*this.scale, 15*this.scale, "nw");
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1]+15*this.scale, 72*this.scale, 34*this.scale, color.福島県, "ne", ctx);

      return nextAreaPoint;
    },
    drawKantoArea(startx, starty, ctx, color) {
      let nextPoint = this.draw_rectangle(startx, starty, 24*this.scale, 100*this.scale, color.岐阜県, "sw", ctx, "ne");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 36*this.scale, 31.5*this.scale, color.愛知県, "ne", ctx);
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1], 40*this.scale, 31.5*this.scale, color.静岡県, "cen", ctx, 15*this.scale, 15*this.scale, "ne");
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1]-16.5*this.scale, 50.5*this.scale, 31.5*this.scale, color.神奈川県, "cen", ctx, 15*this.scale, 15.5*this.scale, "sw");
      nextPoint = this.draw_2_diff_squares(subPoint[0], subPoint[1], 40*this.scale, 100*this.scale, color.長野県, "cen", ctx, 20*this.scale, 30*this.scale, "se", "ne");
      subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 36*this.scale, 30*this.scale, color.山梨県, "ne", ctx);
      nextPoint = this.draw_2_diff_squares(nextPoint[0], nextPoint[1], 52*this.scale, 30*this.scale, color.東京都, "cen", ctx, 20.5*this.scale, 12*this.scale, "se");
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 33*this.scale, 34*this.scale, color.群馬県, "sw", ctx, "ne");
      subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 68*this.scale, 36*this.scale, color.埼玉県, "sw", ctx, "ne");
      nextPoint = this.draw_rectangle(subPoint[0], subPoint[1], 35*this.scale, 34*this.scale, color.栃木県, "ne", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 25*this.scale, 45*this.scale, color.茨城県, "sw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 25*this.scale, 87*this.scale, color.千葉県, "nw", ctx);

      return nextPoint;
    },
    drawHokkaidoTohokuArea(startx, starty, ctx, color) {
      let nextPoint = this.draw_2_diff_squares(startx, starty, 120*this.scale, 90*this.scale, color.北海道, "sw", ctx, 90*this.scale, 12*this.scale, "se");
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1]+15*this.scale, 89*this.scale, 34*this.scale, color.青森県, "sw", ctx);
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 44.5*this.scale, 32*this.scale, color.秋田県, "ne", ctx, "sw");
      let subPoint = [nextPoint[2], nextPoint[3]];
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 44.5*this.scale, 32*this.scale, color.岩手県, "ne", ctx);
      nextPoint = this.draw_2_diff_squares(subPoint[0], subPoint[1], 44.5*this.scale, 35*this.scale, color.山形県, "ne", ctx, 17*this.scale, 15*this.scale, "sw");
      nextPoint = this.draw_rectangle(nextPoint[0], nextPoint[1], 44.5*this.scale, 35*this.scale, color.宮城県, "ne", ctx);
    }
  },
  created(){
    
  },
  computed: {
    
  },
  watch: {

  },
  mounted() {
    const canvas = this.$refs.mapCanvas;
    let context = canvas.getContext("2d");
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    
    context.lineWidth = this.lineWeight * 2;
    context.strokeStyle = "rgb(180, 180, 180)";
    const color = this.getPrefectureColorBasedOnFacilityRate();

    let nextStart = this.drawKyushuArea(12*this.scale, 287*this.scale, context, color);
    this.drawShikokuArea(nextStart[0]+15*this.scale, nextStart[1]+85*this.scale, context, color);
    nextStart = this.drawChugokuArea(nextStart[0]+15*this.scale, nextStart[1], context, color);
    nextStart = this.drawKansaiArea(nextStart[0], nextStart[1], context, color);
    nextStart = this.drawHokurikuArea(nextStart[0], nextStart[1], context, color);
    nextStart = this.drawKantoArea(nextStart[0], nextStart[1], context, color);
    nextStart = this.drawHokkaidoTohokuArea(nextStart[0]-64*this.scale, nextStart[1]-285*this.scale, context, color);
  }
}
</script>