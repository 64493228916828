<template>
<!-- Modal -->
  <div v-show="active" :class="modalClass" style="display: block;">
    <div class="modal-dialog">
      <div class="card card-signup card-plain">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">金額帯を選択してください</h5>
            <button type="button" class="close" @click="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <select-accordion
              ref="selectAccordion"
              v-for="price in prices"
              :key="price.unit"
              :name="price.unit"
              :items="price.ranges"
              :initItems="initPrice"
              @add-item="addItem"
              @delete-item="deleteItem"
            />
          </div>
          <div class="modal-footer mt-2">
            <button type="button" class="cancel-button mr-2" @click="close()">キャンセル</button>
            <button type="button" class="select-button" @click="clickAddButton">追加</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectAccordion from './selectAccordion.vue'
export default {
  data() {
    return {
      active: false,
      selectedItems: []
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    prices: {
      type: Array,
      default: () => []
    },
    initPrice: {
      type: Array,
      default: () => []
    }
  },
  components: {
    selectAccordion
  },
  methods: {
    close(){
      this.active = false;
      this.$emit("close");
    },
    clickAddButton(){
      let addItems = [];
      this.prices.forEach(price => {
        price.ranges.forEach(range => {
          if(this.selectedItems.includes(range.id)){
            addItems.push({label: `${price.unit}/${range.name}`, id: range.id});
          }
        })
      });
      this.$emit("add-price",addItems);
      this.close();
    },
    addItem(event){
      this.selectedItems.push(event)
    },
    deleteItem(event){
      this.selectedItems = this.selectedItems.filter(x => x != event)
    }
  },
  created(){
    this.selectedItems = this.initPrice.map(x => x.id);
  },
  computed: {
    modalClass(){
      return this.active ? "modal show fadein" : "modal"
    },
  },
  watch: {
    isActive(val){
      this.modalStyle = "display: block;"
      this.active = val
    }
  }
}
</script>