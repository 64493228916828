<template>
<!-- Modal -->
  <div v-show="active" :class="modalClass" style="display: block;">
    <div class="modal-dialog">
      <div class="card card-signup card-plain">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{itemName}}を選択してください</h5>
            <button type="button" class="close" @click="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span v-for="item in statusItems" :key="item.id">
              <span v-if="!item.isSelected" @click="selectItem(item)" class="select-item">{{item.name}}</span>
              <span v-else @click="unSelectItem(item)" class="select-item selected">{{item.name}}</span>
            </span>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="cancel-button mr-2" @click="close()">キャンセル</button>
            <button type="button" class="select-button" @click="clickAddButton">追加</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      statusItems: [],
      initSelectedNames: []
    }
  },
  props: {
    itemName: {
      type: String,
      default: "項目"
    },
    isActive: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    initItems: {
      type: Array,
      default: () => []
    }
  },
  components: {
  },
  methods: {
    close(){
      this.active = false;
      this.$emit("close");
    },
    clickAddButton(){
      let addItems = [];
      this.statusItems.forEach(item => {
        if(item.isSelected) addItems.push({label: item.name, id: item.id})
      });
      this.$emit("add-item",addItems);
      this.close();
    },
    selectItem(item){
      item.isSelected = true;
    },
    unSelectItem(item){
      item.isSelected = false;
    },
    unSelectItemFromId(id){
      const unSelectItem = this.statusItems.find(x => x.id == id);
      unSelectItem.isSelected = false;
    },
    
  },
  created(){
    this.statusItems = this.items.map(x => {
      return {id: x.id, name: x.name, isSelected: false};
    })

    this.initSelectedNames = this.initItems.map(x => x.label);

    this.statusItems.forEach(item => {
      item.isSelected = this.initSelectedNames.indexOf(item.name) != -1
    });
  },
  computed: {
    modalClass(){
      return this.active ? "modal show fadein" : "modal"
    }
  },
  watch: {
    isActive(val){
      this.modalStyle = "display: block;"
      this.active = val
    }
  }
}
</script>