<template>
  <div class="a-accordion border-bottom">
    <div @click="opened = !opened" class="row">
      <div class="col-sm-11 col-10">{{name}}</div>
      <div class="col-sm-1 col-1" v-show="!opened"><i class="fas fa-angle-right"></i></div>
      <div class="col-sm-1 col-1" v-show="opened"><i class="fas fa-angle-down"></i></div>
    </div>
    <!-- <button @click="opened = !opened">
      Click
    </button> -->
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-if="opened" class="a-accordion-inner">
        <span v-for="item in statusItems" :key="item.id">
          <span v-if="!item.isSelected" @click="selectItem(item)" class="select-item">{{item.name}}</span>
          <span v-else @click="unSelectItem(item)" class="select-item selected">{{item.name}}</span>
        </span>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      opened: false,
      statusItems: [],
      initSelectedNames: []
    }
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    initItems: {
      type: Array,
      default: () => []
    }
  },
  components: {
    
  },
  methods: {
    selectItem(item){
      item.isSelected = true;
      this.$emit("add-item", item.id);
    },
    unSelectItem(item){
      item.isSelected = false;
      this.$emit("delete-item", item.id);
    },
    unSelectItemFromId(id){
      const unSelectItem = this.statusItems.find(x => x.id == id);
      unSelectItem.isSelected = false;
      this.$emit("delete-item", unSelectItem.id);
    },
    beforeEnter(el) {
      el.style.height = '0'
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    beforeLeave(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    leave(el) {
      el.style.height = '0'
    }
  },
  created(){
    this.statusItems = this.items.map(x => {
      return {id: x.id, name: x.name, isSelected: false}
    })

    this.initSelectedNames = this.initItems.map(x => x.label);

    this.statusItems.forEach(item => {
      item.isSelected = this.initSelectedNames.indexOf(this.name + "/" + item.name) != -1
    });
  },
  computed: {
    modalClass(){
      return this.active ? "modal show fadein" : "modal"
    },
  },
  watch: {
    isActive(val){
      this.modalStyle = "display: block;"
      this.active = val
    },
  }
}
</script>
<style scoped lang="scss">
.a-accordion {
  .a-accordion-inner {
    overflow: hidden; // 閉じるときに他要素に被らないように必須。
    transition: height 0.2s ease-in-out; // 高さの変更に対して連続的に変化させる。
    margin-bottom: 5px;
  }
}
</style>